.hero {
  position: relative;
  height: 100vh;
  background-image: url("../../../../assets/products/solor-bg1.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  color: #ffffff;
}


.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.2));
}

.text {
  position: absolute;
  left: 24px;
  padding: 24px;
}

