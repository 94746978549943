body {
  margin: 0;
  padding: 0;
    font-family: "DM Sans";

}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color:#ff8229;
  border-radius: 4px;
}